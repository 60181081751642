<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@font-face {
  font-family: 'VCR OSD Mono';
  src: url('./assets/fonts/VCROSDMono.woff2') format('woff2'),
    url('./assets/fonts/VCROSDMono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.font-arcade {
  font-family: 'VCR OSD Mono';
  font-weight: normal;
  font-style: normal;
}
</style>
