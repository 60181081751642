<template>
  <v-main>
    <v-container v-if="!submitted" fluid class="fill-height">
      <v-row>
        <v-col cols="12">
          <div class="pa-4">
            <v-img src="../assets/images/logo.png" max-width="100%"></v-img>
          </div>
          <p class="pa-4 text-center font-arcade">
            Pour avoir la chance de gagner un des lots Bose, envoyez la photo de
            votre score accompagné de votre nom, prénom et mail afin que l’on
            puisse vous contacter.
          </p>
          <v-form @submit.prevent="submit" class="pa-4">
            <v-text-field
              v-model="form.lastname"
              label="Nom"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="form.firstname"
              label="Prénom"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="form.email"
              label="Email"
              type="email"
              outlined
              prepend-inner-icon="mdi-email"
            ></v-text-field>

            <v-select
              v-model="form.store"
              :items="stores"
              label="Magasin"
              outlined
            ></v-select>

            <v-text-field
              v-model="form.score"
              label="Score"
              type="number"
              outlined
            ></v-text-field>

            <v-file-input
              v-model="form.file"
              accept="image/*"
              label="Photo"
              outlined
              :prepend-icon="null"
              prepend-inner-icon="mdi-camera"
            ></v-file-input>

            <p class="pa-4 text-center font-arcade">
              *règlement disponible auprès de votre vendeur en magasin
            </p>

            <v-btn
              type="submit"
              block
              color="grey darken-3"
              large
              :loading="processing"
            >
              <v-icon left>
                mdi-cloud-upload
              </v-icon>
              Envoyer
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid class="fill-height">
      <v-row>
        <v-col cols="12">
          <div class="pa-4">
            <v-img src="../assets/images/logo.png" max-width="100%"></v-img>
          </div>
          <p class="pa-4 text-center font-arcade">
            C'est envoyé, merci !
          </p>
          <div class="pa-4 text-center">
            <v-btn text @click="reset">
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Retour
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="isErrorForm"
      color="red darken-1"
      centered
      :timeout="3000"
    >
      Veuillez vérifier le formulaire
    </v-snackbar>
    <v-snackbar
      v-model="isErrorSending"
      color="red darken-1"
      centered
      :timeout="3000"
    >
      Erreur, veuillez réessayer
    </v-snackbar>
  </v-main>
</template>

<script>
import axios from 'axios';

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export default {
  name: 'Home',
  data: () => ({
    processing: false,
    submitted: false,
    isErrorForm: false,
    isErrorSending: false,
    stores: [
      'BOULANGER PORTET-SUR-GARONNE',
      'BOULANGER GRANDE SYNTHE',
      'BOULANGER ST HERBLAIN',
      'BOULANGER NICE',
      'BOULANGER FREJUS',
      'BOULANGER LYON CORDELIERS',
      'BOULANGER VELIZY 2',
      'BOULANGER STE GENEVIEVE DES BOIS',
      'BOULANGER TOULON',
      'BOULANGER PLAN DE CAMPAGNE',
    ],
    form: {
      lastname: null,
      firstname: null,
      email: null,
      file: null,
    },
  }),
  methods: {
    async submit() {
      if (
        this.form.lastname &&
        this.form.firstname &&
        validateEmail(this.form.email) &&
        this.form.file
      ) {
        this.processing = true;

        const formData = new FormData();
        formData.append('lastname', this.form.lastname);
        formData.append('firstname', this.form.firstname);
        formData.append('email', this.form.email);
        formData.append('store', this.form.store);
        formData.append('score', this.form.score);
        formData.append('file', this.form.file);

        try {
          await axios({
            method: 'post',
            url: `${process.env.VUE_APP_SERVER_API}/entries`,
            data: formData,
            config: {
              headers: {
                'content-type': 'multipart/form-data',
              },
            },
          });

          this.submitted = true;
        } catch (error) {
          this.isErrorSending = true;
        } finally {
          this.processing = false;
        }
      } else {
        this.isErrorForm = true;
      }
    },
    reset() {
      this.submitted = false;
      this.isErrorForm = false;
      this.isErrorSending = false;
      this.form = {
        lastname: null,
        firstname: null,
        email: null,
        store: null,
        score: null,
        file: null,
      };
    },
  },
};
</script>
